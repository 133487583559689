<template>
  <v-container class="mt-5 mt-md-10">
    <v-alert
      v-if="$store.state.balance <= 0"
      type="warning"
      class="text-center"
      dense
    >
      <small>رصيد حسابك صفر لا يمكنك ارسال رصيد للعملاء</small>
    </v-alert>
    <v-alert v-if="alert" dense type="error" class="text-center"
      >هذا العميل غير موجود</v-alert
    >

    <v-alert v-if="success" dense type="success" class="text-center"
      >تم جلب بيانات العميل
    </v-alert>
    <v-row v-if="$store.state.balance > 0">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-text-field
              type="number"
              v-model="search"
              label="بحث عن المستخدم برقم الهاتف"
            ></v-text-field>
            <v-btn
              :disabled="search_loading"
              :loading="search_loading"
              @click="get_user()"
              color="primary"
              >بحث</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-list elevation="2" dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              user_data ? user_data.name : "........"
            }}</v-list-item-content>
          </v-list-item>
          <!--/ customer name -->

          <v-list-item>
            <v-list-item-icon>
              <v-icon>phone_android</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              user_data ? user_data.phone : "........"
            }}</v-list-item-content>
          </v-list-item>
          <!--/ customer phone -->
          <v-list-item>
            <v-list-item-icon>
              <v-icon>alternate_email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              user_data ? user_data.email : "........"
            }}</v-list-item-content>
          </v-list-item>
          <!--/ customer email  -->
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-form ref="form">
          <v-card>
            <v-card-title>بيانات الأرسال</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-alert
                type="warning"
                dense
                class="text-center mb-3"
                v-if="no_user"
              >
                <small> برجاء البحث عن العميل ثم ارسال المبلغ</small>
              </v-alert>

              <v-alert
                type="error"
                dense
                class="text-center mb-3"
                v-if="no_amount"
              >
                <small> لا يوجد رصيد كافى لأرسال المبلغ</small>
              </v-alert>

              <v-alert
                type="error"
                dense
                class="text-center mb-3"
                v-if="password_wrong"
              >
                <small>كلمة المرور غير صحيحة</small>
              </v-alert>

              <v-text-field
                :rules="rules"
                type="number"
                v-model="amount"
                label="المبلغ"
                @input="amount_changed($event)"
              ></v-text-field>

              <v-text-field
                hide-details
                class="mb-4 mt-4"
                filled
                label="تفنيط المبلغ"
                disabled
                v-model="input_amount"
              >
              </v-text-field>
              <v-text-field
                class="mb-2"
                label="كلمة مرور الحساب"
                type="password"
                v-model="password"
                :rules="passwordRules"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="send_loading"
                :loading="send_loading"
                @click="send_balance()"
                color="primary"
              >
                ارسال
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar right v-model="snackbar">
      تم ارسال الرصيد بنجاح

      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" color="success"> check_circle </v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "certificate",
  data() {
    return {
      password_wrong: false,
      password: null,
      input_amount: 0,
      snackbar: false,
      send_loading: false,
      no_user: false,
      search_loading: false,
      search: "",
      amount: 0,
      user_data: null,
      alert: false,
      success: false,
      no_amount: false,
      passwordRules: [(v) => !!v || "كلمة المرور مطلوبة"],
      rules: [
        (v) => !!v || "يجب ادخال المبلغ",
        (v) => !(v <= 0) || "المبلغ غير صحيح",
      ],
    };
  },
  methods: {
    get_user() {
      if (this.search) {
        this.search_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `customer_balance/${this.search}`,
              method: "get",
            },
          })
          .then((res) => {
            this.alert = false;
            this.success = true;
            this.user_data = res.data.data;
            this.search_loading = false;
            this.no_user = false;
            this.no_amount = false;
          })
          .catch((err) => {
            this.no_amount = false;
            this.no_user = false;
            this.user_data = null;
            this.alert = true;
            this.success = false;
            this.search_loading = false;
          });
      }
    },
    send_balance() {
      if (this.$refs.form.validate() && this.user_data) {
        this.no_user = false;
        if (
          this.$store.state.balance >= this.amount &&
          this.$store.state.balance != 0
        ) {
          this.send_loading = true;
          this.$store
            .dispatch("public__request", {
              config: {
                url: "customer_balance",
                method: "post",
              },
              data: {
                id: this.user_data.id,
                amount: this.amount,
                password: this.password,
              },
            })
            .then((res) => {
              this.snackbar = true;
              this.no_user = false;
              this.send_loading = false;
              this.amount = 0;
              this.user_data = null;
              this.success = false;
              this.no_amount = false;
              this.password_wrong = false;
              this.$refs.form.resetValidation();
              this.get_balance();
            })
            .catch((err) => {
              this.send_loading = false;
              if (err.response.data.data == "password_problem") {
                this.password_wrong = true;
              } else {
                this.no_user = false;
              }
            });
        } else {
          this.no_amount = true;
        }
      } else {
        this.no_user = true;
        this.no_amount = false;
      }
    },
    get_balance() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "customer_balance",
            method: "get",
          },
        })
        .then((res) => {
          this.$store.state.balance = res.data.data.balance;
          this.$store.state.certificates_balance =
            res.data.data.certificates_balance;
        });
    },
    amount_changed(ev) {
      let val = parseInt(ev);
      // this.amount = val;
      val
        ? (this.input_amount = Intl.NumberFormat("en-US").format(
            val.toFixed(4)
          ))
        : (this.input_amount = 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-label--is-disabled,
  .theme--light.v-input--is-disabled input {
    color: #000;
  }
}
</style>
